import React from 'react';
import IssueFormWrap from '../components/IssueFormWrap/IssueFormWrap';
import { AgtraceLinkResolver, AgtraceLinkResponse, IdentificationKeyType, LinkType, MimeType } from '../models/gs1';
import { CredentialPayload, VerifiableCredential } from '@vckit/core-types';
import { JsonFormData, defaultIssueFormValue } from '../models/common';
import { createLinkResolver } from '../services/linkResolver.service';
import { uploadJson, BucketName } from '../services/uploadJson.service';
import { integrateVckitIssueVC } from '../services/vckit.service';
import { credentialsIssue } from '../schemas';
import { generateUUID } from '../utils/helpers';

const formName = 'DigitalLivestock';
const title = 'Digital Livestock Passport';
/**
 * DigitalLivestock component is used to display the schema of DigitalLivestock.
 */
export const DigitalLivestock = () => {
    const [dynamicSchema, setDynamicSchema] = React.useState<any>(null);

    React.useEffect(() => {
        if (!dynamicSchema) {
            const schema: any = credentialsIssue[formName].schema;
            setDynamicSchema(schema);
        }
    }, []);

    const processor = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            const vc = await issueCredential(formData, credentialPayload);
            const NLISID = formData['herd']['NLIS'];
            const vcUrl = await uploadVC(`${NLISID}/${generateUUID()}`, vc);
            await registerLinkResolver(vcUrl, IdentificationKeyType.nlisid, NLISID, title);
            return { vc };
        } catch (error: any) {
            return { errorMessage: error.message };
        }
    };

    /**
     * issue credential
     */
    const issueCredential = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { schema, uischema, description, context, data, ...restOfVC } = credentialsIssue[formName];

            const result: VerifiableCredential = await integrateVckitIssueVC({
                context,
                credentialSubject: formData,
                ...credentialPayload,
                restOfVC,
            });

            const credentialValue = result;
            return credentialValue;
        } catch (error) {
            throw new Error('Error issue credential');
        }
    };

    /**
     * upload VC to S3 after issue credential success and get the passport link
     */
    const uploadVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await uploadJson(filename, BucketName.PublicVC, vc);
        return result;
    };

    /**
     * register link resolver to GS1 after getting from uploadVC
     */
    const registerLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        passportTitle: string,
    ) => {
        const linkResolver: AgtraceLinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: `${defaultIssueFormValue[formName].title}`,
        };
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `/?q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
        const linkResponses: AgtraceLinkResponse[] = [
            {
                linkType: LinkType.verificationLinkType,
                linkTitle: 'VCKit verify service',
                targetUrl: (process.env.REACT_APP_VERIFICATION_TARGET_URL as string) || '',
                mimeType: MimeType.textPlain,
            },
            {
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        ];

        await createLinkResolver(linkResolver, linkResponses, queryString);
    };

    return (
        <>
            {dynamicSchema && <IssueFormWrap formName={formName} processor={processor} dynamicSchema={dynamicSchema} />}
        </>
    );
};

import { v4 as uuidv4 } from 'uuid';

export function convertBase64ToString(base64: string) {
    return Buffer.from(base64, 'base64').toString('utf8');
}

export function detectDevice(userAgent: string) {
    const userAgentLowerCase = userAgent.toLowerCase();

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgentLowerCase)) {
        return 'mobile';
    } else if (/mac|win/i.test(userAgentLowerCase)) {
        return 'laptop';
    } else {
        return 'unknown';
    }
}

/**
 * @returns a random string of 5 characters
 */
export function randomString() {
    return Math.random().toString(36).slice(2, 7);
}

export function generateUUID() {
    return uuidv4();
}

export function splitStringByDash(string: string) {
    return string.split('-');
}
